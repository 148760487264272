<template>
  <b-sidebar
    id="sidebar-detail"
    v-model="isDetailsActive"
    title="Sales Process Commissions"
    header-class="text-primary pt-1 mb-2"
    width="85%"
    bg-variant="white"
    shadow
    backdrop
    right
    @hidden="close"
  >
    <div class="mb-2 px-2">
      <b-nav
        pills
        class="m-0"
      >
        <b-nav-item
          exact
          :active="tab === 1"
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          @click="tab = 1"
        >In process</b-nav-item>
        <b-nav-item
          exact
          :active="tab === 2"
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          @click="tab = 2"
        >Processed</b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <sales-process-commissions-table :status="tab" />
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import SalesProcessCommissionsTable from '@/views/commons/components/commissions/tables/SalesProcessCommissionsTable.vue'

export default {
  components: {
    SalesProcessCommissionsTable,
  },
  data() {
    return {
      isDetailsActive: false,
      tab: 1,
    }
  },
  mounted() {
    this.isDetailsActive = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
