<template>
  <filter-slot
    :total-rows="totalRows"
    :paginate="paginate"
    :start-page="startPage"
    :to-page="toPage"
    :filter="filters"
    :filter-principal="filterPrincipal"
    @reload="$refs['refTrackingTable'].refresh()"
  >
    <template #table>
      <b-table
        ref="refTrackingTable"
        no-border-collapse
        class="position-relative"
        :fields="visibleFields"
        show-empty
        no-provider-filtering
        sticky-header="60vh"
        primary-key="id"
        responsive="sm"
        :items="searchCedSettingsCommission"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(new_value)="data">
          <div v-if="data.item.category_description == 'CHARGE'">
            {{ data.item.type_commission !== null ? data.item.type_commission == "AMOUNT" ? `$ ${data.item.new_value}` : `${data.item.new_value} %` : '0.00'}}
          </div>
          <div v-else>
            {{ data.item.type_comission_number === 1? '$' : ' ' }}
            {{ data.item.new_value }}
            {{ data.item.type_comission_number === 2? '%' : ' ' }}
          </div>
        </template>
        <template #cell(old_value)="data">
          <div v-if="data.item.category_description == 'CHARGE'">
            {{ data.item.old_type_commission !== null ? data.item.old_type_commission == "AMOUNT" ? `$ ${data.item.old_value}` : `${data.item.old_value} %` : '0.00' }}
          </div>
          <div v-else>
            {{ data.item.type_comission_number === 1? '$' : ' ' }}
          {{ data.item.old_value }}
          {{ data.item.type_comission_number === 2? '%' : ' ' }}
          </div>
        </template>
        <template #cell(category_description)="data">
          <b-badge
            :variant="getVariantCategory(data.item.category_number)"
            class="text-center w-100"
          >
            {{ data.item.category_description }}
          </b-badge>
        </template>
        <template #cell(created_by)="data">
          {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
        </template>
        <template #cell(evaluated_by)="data">
          {{ data.item.evaluated_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
        </template>
        <template #cell(status_description)="data">
          <b-badge
            :variant="getVariantColor(data.item.status)"
            class="text-center w-100"
          >
            {{ data.item.status_description }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <b-button
              v-b-modal.modal-action
              v-b-tooltip.hover.top="'Approve'"
              class="button-rounded"
              variant="primary"
              @click="approveDisapproveChangeCommission(data.item, 2)"
            >
              <feather-icon
                icon="ThumbsUpIcon"
                size="15"
              />
            </b-button>
            <b-button
              v-b-modal.modal-action
              v-b-tooltip.hover.top="'Disapprove'"
              class="button-rounded"
              variant="danger"
              @click="approveDisapproveChangeCommission(data.item, 3)"
            >
              <feather-icon
                icon="ThumbsDownIcon"
                size="15"
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </template>
  </filter-slot>
</template>

<script>
import SettingsCommissionsFilters from '@/views/commons/components/commissions/tables/data/settings.commissions.filters'
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import CedDashboardTracking from '@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/service/ced-dashboard-tracking'
import { mapGetters } from 'vuex'

export default {
  props: {
    status: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      fields: [
        {
          key: 'type_description',
          sortable: false,
          label: 'Type',
          visible: true,
        },
        {
          key: 'category_description',
          sortable: false,
          label: 'Category',
          visible: true,
        },
        {
          key: 'old_value',
          sortable: false,
          label: 'Old Value',
          visible: true,
        },
        {
          key: 'new_value',
          sortable: false,
          label: 'New Value',
          visible: true,
        },
        {
          key: 'module_name',
          sortable: false,
          label: 'Module',
          visible: true,
        },
        {
          key: 'created_by',
          sortable: true,
          label: 'Requested by',
          visible: true,
        },
        {
          key: 'evaluated_by',
          sortable: true,
          label: 'Processed by',
          visible: true,
        },
        {
          key: 'status_description',
          sortable: false,
          label: 'Status',
          visible: true,
          thStyle: {
            textAlign: 'center',
          },
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
          visible: true,
          thStyle: {
            textAlign: 'center',
          },
        },
      ],
      filters: SettingsCommissionsFilters,
      trackingItems: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      commissionTypes: [],
    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(column => column.visible)
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
    async status() {
      this.fields[6].visible = this.status !== 1
      this.fields[7].visible = this.status !== 1
      this.fields[8].visible = this.status === 1
      this.filters[2].visible = this.status !== 1
      this.filters[5].visible = this.status !== 1
      await this.$refs.refTrackingTable.refresh()
    },
  },
  async created() {
    await this.getCategoryCommission()
    await this.getCommissionTypes()
    await this.getDigitalSubmodules()
    this.fields[6].visible = this.status !== 1
    this.fields[7].visible = this.status !== 1
    this.fields[8].visible = this.status === 1
    this.filters[2].visible = this.status !== 1
    this.filters[5].visible = this.status !== 1
  },
  methods: {
    async getCommissionTypes() {
      const data = await CeSetCommissionsService.getCommissionData()
      if (data.status === 200) {
        this.filters[4].options = data.data
      }
    },
    async getCategoryCommission(type) {
      const data = await CeSetCommissionsService.getCategoryCommission(type)
      if (data.status === 200) {
        this.filters[3].options = data.data
      }
    },
    async getDigitalSubmodules() {
      const data = await CedDashboardTracking.getDigitalSubmodules()
      if (data.status === 200) {
        this.filters[5].options = data.data
      }
    },
    async searchCedSettingsCommission(ctx) {
      const response = await CeSetCommissionsService.searchCedSettingsCommission({
        from: this.filters[0].model,
        to: this.filters[1].model,
        status: this.filters[2].model,
        in_process: this.status === 1 ? 1 : 2,
        category_id: this.filters[3].model,
        type_id: this.filters[4].model,
        page: ctx.currentPage,
        order: ctx.sortDesc ? 'desc' : 'asc',
        orderby: this.status === 1 ? 'created_at' : 'updated_at',
        perPage: ctx.perPage,
        text: this.filterPrincipal.model,
        module_id: this.filters[5].model,
      })
      this.trackingItems = response.data.data
      this.startPage = response.data.from
      this.paginate.currentPage = response.data.current_page
      this.paginate.perPage = response.data.per_page
      this.totalRows = response.data.total
      this.toPage = response.data.to
      return this.trackingItems || []
    },
    getVariantColor(status) {
      switch (status) {
        case 1:
          return 'light-primary'
        case 2:
          return 'light-success'
        case 3:
          return 'light-danger'
        default:
          return 'light-info'
      }
    },
    getVariantCategory(status) {
      switch (status) {
        case 1:
          return 'light-primary'
        case 2:
          return 'light-warning'
        case 3:
          return 'light-success'
        default:
          return 'light-info'
      }
    },
    async approveDisapproveChangeCommission(item, decision) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CeSetCommissionsService.updateStatusCommission({
            tracking_id: item.id,
            status: decision,
            user_id: this.currentUser.user_id,
            type_table: item.type_table,
            user_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
            new_value: item.new_value,
            old_value: item.old_value,
            type_commission_description: item.type_description,
            type_data_commission: item.type_comission_number,
            created_by_id: item.created_by_id,
            module_id: item.module_id,
            old_type_commission: item.old_type_commission
          })
          if (data.status === 200) {
            this.showSuccessSwal()
            this.$refs.refTrackingTable.refresh()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>
.button-rounded {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
