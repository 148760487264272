<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <b-button
          variant="primary"
          @click="openSidebarRequests"
        >
          <span>Roles and Departments commissions</span>
          <b-badge
            v-if="counterPendingApprovalRequests > 0"
            pill
            variant="danger"
            style="margin-left: 0.5rem"
          >
            {{ counterPendingApprovalRequests }}
          </b-badge>
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="openSalesProcessCommissions"
        >
          <span>Sales Process Commissions</span>
          <b-badge
            v-if="counterPendingSalesProcessCommissions > 0"
            pill
            variant="danger"
            style="margin-left: 0.5rem"
          >
            {{ counterPendingSalesProcessCommissions }}
          </b-badge>
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="openApprovedCreatedCommissions"
        >
          <span>Dynamic Commissions</span>
          <b-badge
            v-if="counterPendingCreatedCommissions > 0"
            pill
            variant="danger"
            style="margin-left: 0.5rem"
          >
            {{ counterPendingCreatedCommissions }}
          </b-badge>
        </b-button>
      </template>
    </header-slot>
    <b-tabs
      v-model="tabController"
      lazy
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-primary box-shadow-info"
    >
      <b-tab lazy title="CRM" :title-link-class="[bgTabsNavs, 'px-3']">
        <CommissionsComponent tab="crm" />
      </b-tab>
      <b-tab
        lazy
        title="ADMINISTRATION"
        :title-link-class="[bgTabsNavs, 'px-3']"
      >
        <CommissionsComponent tab="adm" />
      </b-tab>
      <b-tab lazy title="CONNECTION" :title-link-class="[bgTabsNavs, 'px-3']">
        <CommissionsComponent tab="connection" />
      </b-tab>
      <b-tab lazy title="Departments" :title-link-class="[bgTabsNavs, 'px-3']">
        <b-card
          no-body
          :class="'border-top-primary border-3 box-shadow-3 rounded-bottom border-table-radius pb-1'"
        >
          <b-tabs
            v-model="programIndex"
            pills
            active-nav-item-class="bg-orange-tab"
            nav-wrapper-class="ml-2 mt-1 program-tabs"
            lazy
            content-class="ml-2"
          >
            <b-tab
              v-for="(program, index) in programs"
              :key="index"
              title-item-class="text-center w-9"
              :title-link-class="[bgTabsNavs, 'px-3', 'bg-default-tab']"
            >
              <template #title>
                <span>{{ program.initial }}</span>
              </template>
              <b-card no-body>
                <CommissionsComponent
                  :tab="program.id.toString()"
                />
              </b-card>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-tab>
    </b-tabs>
    <sidebar-pending-approval
      v-if="openSidebarRequestsSidebar"
      @close="closeSidebarRequestsSidebar"
    />
    <approve-created-commissions-table
      v-if="openApprovedCreatedCommissionsOn"
      @close="closeApprovedCreatedCommissions"
    />
    <sidebar-sales-process-commissions
      v-if="openSalesProcessCommissionsOn"
      @close="openSalesProcessCommissionsOn = false"
    />
  </div>
</template>
<script>
import CommissionsComponent from "@/views/commons/components/commissions/CommissionsComponent.vue";
import SidebarPendingApproval from "@/views/commons/components/commissions/sidebars/SidebarPendingApproval.vue";
import ApproveCreatedCommissionsTable from "@/views/commons/components/commissions/tables/ApproveCreatedCommissionsTable.vue";
import SidebarSalesProcessCommissions from '@/views/commons/components/commissions/sidebars/SidebarSalesProcessCommissions.vue'
import { mapGetters } from "vuex";
import GlobalServices from "@/views/services/global.service";
import CeSetCommissionsService from "@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service";

export default {
  components: {
    CommissionsComponent,
    SidebarPendingApproval,
    ApproveCreatedCommissionsTable,
    SidebarSalesProcessCommissions,
  },
  data() {
    return {
      programs: [],
      programIndex: 0,
      activeProgram: 0,
      tabController: 0,
      openSidebarRequestsSidebar: false,
      counterPendingApprovalRequests: 0,
      counterPendingSalesProcessCommissions: 0,
      counterPendingCreatedCommissions: 0,
      openApprovedCreatedCommissionsOn: false,
      openSalesProcessCommissionsOn: false,
    }
  },
  async created() {
    await this.getCountRequestsForApprovalPending()
    await this.getCountRequestsForCreatedCommissions()
    await this.getCountRequestsForSalesProcessCommissions()
  },
  methods: {
    async getPrograms() {
      this.programs = await GlobalServices.getPrograms();
      this.programs.push({ id: 15, initial: "SN", value: "Social Network" });
      //   this.programs.splice(0, 1);
    },
    async getCountRequestsForApprovalPending() {
      const data =
        await CeSetCommissionsService.getCountRequestsForApprovalPending();
      this.counterPendingApprovalRequests = data.data[0].cc;
    },
    async getCountRequestsForCreatedCommissions() {
      const data = await CeSetCommissionsService.getCountRequestsForCreatedCommissions()
      this.counterPendingCreatedCommissions = data.data[0].cc
    },
    async getCountRequestsForSalesProcessCommissions() {
      const data = await CeSetCommissionsService.getCountRequestsForSalesProcessCommissions()
      this.counterPendingSalesProcessCommissions = data.data
    },
    openSidebarRequests() {
      this.openSidebarRequestsSidebar = true;
    },
    async closeSidebarRequestsSidebar() {
      this.openSidebarRequestsSidebar = false;
      await this.getCountRequestsForApprovalPending();
    },
    closeApprovedCreatedCommissions() {
      this.openApprovedCreatedCommissionsOn = false;
    },
    openApprovedCreatedCommissions() {
      this.openApprovedCreatedCommissionsOn = true
    },
    openSalesProcessCommissions() {
      this.openSalesProcessCommissionsOn = true
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    tabController(newVal) {
      // if tab is departments call get programs function with social network as last program
      if (newVal == 3) {
        this.getPrograms();
      }
    },
  },
};
</script>
<style lang="scss" >
.program-tabs {
  ul {
    margin-bottom: 0px !important;
  }
}
</style>
